
const config = {
  editInBitbucketButton: {
    isVisible: process.env.GATSBY_SHOW_EDIT_IN_BITBUCKET_BUTTON
  },
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://mimik.com',
    gaTrackingId: 'G-JFFLQ8P9EL',
    getTagManagerTrackingId: "GTM-T2WXR64",
    linkedInPartnerId: '3195972',
    trailingSlash: false,
  },
  header: {
    logo: 'https://developer.mimik.com/wp-content/uploads/2021/10/498ec051afc558f8b4f25be6eed00c71.jpg',
    logoLink: 'https://devdocs.mimik.com',
    title:
      "",
    githubUrl: 'https://github.com/mimikgit',
    helpUrl: '',
    tweetText: '',
    social: `<li><div></li>
    <li><div></li>
    <li><div></li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: process.env.GATSBY_INDEX_NAME || process.env.ALGOLIA_INDEX_NAME,
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_API_KEY
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/key-concepts',
      '/tutorials',
      '/api',
      '/faqs',
      '/troubleshooting',
      '/misc'
    ],
    collapsedNav: [
      '/introduction',
      '/key-concepts',
      '/tutorials',
      '/api',
      '/faqs',
      '/misc'
    ],
    links: [{ text: 'Developer Portal', link: 'https://developer.mimik.com'},
    { text: 'Developer Console', link: 'https://console.mimik.com' },
    { text: 'mimik website', link: 'https://mimik.com' }],
    frontline: false,
    ignoreIndex: true,
    title:
      "mimik Developer Documentation",
  },
  siteMetadata: {
    title: 'mimik Developer Portal | mimik',
    description: 'Documentation built with mdx. Powering mimik.com ',
    ogImage: null,
    docsLocation: 'https://bitbucket.org/mimiktech/dev-portal-content/src/develop/content/'
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'mimik Developer Portal',
      short_name: 'mimikDeveloper',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
