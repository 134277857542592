import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Link from '../link';
import useStackOverflowHook from '../../hooks/useStackOverflowHook'

 

const ListBlock = styled('div')`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;

  & a {
    color: #000000;
    &:hover {
        color: #F6911E;
        text-decoration:underline;
    }
  }
  
`;

const DataItems = (props) => {
        if (Array.isArray(props.items)) {
            return <ul>
                {props.items.map((item, index) => {
                    return <li key={index} ><Link to={item.link}>{item.title}</Link></li>
                })}
            </ul>
        }
    return <></>
}

const TroubleshootingList = ({
    theme
}) => {

    const [myData,setMyData] = useState([])
    const url = 'https://api.stackexchange.com/2.3/questions?order=desc&sort=activity&tagged=edgeEngine&site=stackoverflow&key=xzqzQxmVWnCXZhLvCiP1Tg(('
    const stackOverflowData = useStackOverflowHook(url);
    useEffect(() => {
        setMyData(() => {
            return stackOverflowData.items;
        })
    }, [stackOverflowData])

    return (
        <div className='trobbuleshoot_listing'>
            <ListBlock theme={theme}>
                <DataItems items={myData} ></DataItems>
            </ListBlock>
        </div>

    )

    /*
    return (
        <div>
            <ListBlock>
                <DataItems items={myData} ></DataItems>
            </ListBlock>
            < TSBase >
                <TSRow>
                    <TDPrev>&#8592; Last 10 Questions NOT IMPLEMENTED </TDPrev>
                    <TDNext>Next 10 Questions NOT IMPLEMENTED &#8594;</TDNext>
                </TSRow>
            </TSBase >
        </div>

    )
    */
}
export default TroubleshootingList;