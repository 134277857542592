const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#fff',
    heading: '#000000',
    text: '#000000',
    preFormattedText: 'rgb(245, 247, 249)',
    link: '#F6911E',
    leftSection: '#E5F0C6',
    darkGreen: '#A3CD39',
    lightGreen: '#F5F9E8',
    greenCol: '#7BC12E'
  },
  section : {
    categoriesCol : 'A3CD39',
    arrowCol: '#A3CD39',
    navBorder : '#FFFFFF',
    navActive: '#C1DD7A',
    navActiveCol: '#333333',
    navParentCol: '#000000',
    navChildCol: '#333333',
    searchCol: '#FAFFEE',
    searchBtn: '#A3CD39',
    sidebarLinks: '#7F7F7F',
    sidebarLinksHover: '#333333',
    sidebarLinksBorder:'#7F7F7F',
    navChildBorder: '#A3CD39'
  },
  leftSideBar : {
    catTitle: '#A3CD39',
    menuHover: '#A3CD39',
    menuActive: '#C1DD7A',
    iconNormal: '#A3CD39',
    iconHover : '#C1DD7A'
  },
  pagination : {
    pagingCol: '#A3CD39',
    pagingHoverCol: '#7CA926'
  },
  helpSection : {
    bgCol: '#EDF5D7',
    outerBorder: '#EDF5D7',
    hoverCol: '#A3CD39'
  },
  searchSection: {
    hoverCol : '#E5F0C6',
    hoverBorderCol : '#A3CD39',
    bgSearchPopup: '#F5F9E8',
    listBg: '#ffffff',
    showLink: '#000000'
  },
  breadcums : {
    color: '#C1C1C1'
  }
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#001933',
    heading: '#ffffff',
    text: '#ffffff',
    preFormattedText: '#000000',
    link: '#F6911E',
    leftSection: '#001933',
    darkGreen: '#09A4A7',
    lightGreen: '#001124',  
    greenCol: '#09A4A7'
  },
  section : {
    categoriesCol : '004C48',
    arrowCol: '#7CC9CD',
    navBorder : '#09A4A7',
    navActive: '#09A4A7',
    navActiveCol: '#001933',
    navParentCol: '#09A4A7',
    navChildCol: '#007776',
    searchCol: '#001124',
    searchBtn: '#09A4A7',
    textColors: '#09A4A7',
    sidebarLinks: '#004C48',
    sidebarLinksHover: '#007776',
    sidebarLinksBorder:'#0F3358',
    navChildBorder: '#0F3358',  
  },
  leftSideBar : {
    catTitle: '#004C48',
    menuHover: '#004C48',
    menuActive: '#09A4A7',
    iconNormal: '#7CC9CD',
    iconHover : '#7CC9CD'
  },
  pagination : {
    pagingCol: '#09A4A7',
    pagingHoverCol: '#7CC9CD'
  },
  helpSection : {
    bgCol: '#001124',
    outerBorder: '#09A4A7',
    hoverCol: '#09A4A7'
  },
  searchSection: {
    hoverCol : '#09A4A7',
    hoverBorderCol : '#7CC9CD',
    bgSearchPopup: '#001933',
    listBg: '#0F3358',
    showLink: '#09A4A7'
  },
  breadcums : {
    color: '#004C48'
  }
};

export { lightTheme, darkTheme };
